
import { useEffect, useState } from 'react';
import { notification } from 'antd';

import { useAppStateValue } from '../state/StateContext';
import { fetchUsersProfile } from '../api/users';

import Router from '../routes';
import { Loader } from '../components/Loader';

export default function StreamOfficeApp() {
  const [, dispatch] = useAppStateValue();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validateSession = async () => {
    try {
      setIsLoading(true)
      if (localStorage.getItem('access_token')) {
        const user = await fetchUsersProfile();

        dispatch({ type: 'updateState', value: { isUserLogged: true, user, isStateInitialized: true } })
      }
      else {
        dispatch({ type: 'updateState', value: { isUserLogged: false, isStateInitialized: true } });
      }


    }
    catch (error) {
      notification.error({
        message: 'Something went wrong',
        description: (error.properties && error.properties[0]?.messages[0]) || error.message,
      });

      dispatch({ type: 'updateState', value: { isUserLogged: false, isStateInitialized: true } });

      console.log(error);
      console.log('Coach has to be authorized');
    }
    finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    validateSession();
  }, []);


  if (isLoading) return <Loader />
  return <Router />;
}
