import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import logoBlack from '../../assets/LogoBlack.png';
import './publicPages.scss'

const { Footer, Content } = Layout;

export default function PublicPages() {
  return (
    <Layout className="public-page-container">
      <Layout>
        <Content className='main-container'>
          <img src={logoBlack} alt="ac_logo" style={{ width: 157 }} />
          <Outlet />
        </Content>

        <Footer className='footer-container'>
          © Action Clip 2022. All Rights Reserved.
        </Footer>
      </Layout>
    </Layout>
  );
}
