import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import './skeleton.scss';

import HeaderComponent from './header/HeaderComponent';

const { Footer, Content } = Layout;

export default function Skeleton() {

  return (
    <Layout className="private-layout">

      <HeaderComponent />

      <Content style={{ minHeight: 'calc(100vh - 170px)', display: 'flex' }}>
        <Outlet />
      </Content>

      <Footer className='footer-container'>
        <span>© Action Clip 2022. All Rights Reserved.</span>
      </Footer>
    </Layout >
  )
}