import API from './api'
import axios from './axiosInstance'


export interface UserCredentials {
  email: string;
  password: string;
}

export interface RegisterUserCredentials {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export interface UsersProfile {
  id: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  status: string;
  email: string;
  roles: string[];
  createdAt: string;
  maxViewersCount: number;
  streamsCount: number
  customer: {
    aggregatedBalance: {
      streamingTime: {
        hoursString: string;
        seconds: number;
      };
      note: string;
    }
    id: string;
    paymentAccountId: string;
  },
  linkedTeams: ILinkedTeam[] | null
}
export interface ILinkedTeam {
  id: string
  name: string
  code: string
  createdAt: string
  updatedAt: string
}



export async function signIn(
  userCredentials: UserCredentials
): Promise<LoginResponse> {

  const { data } = await axios.post(`/auth/login`, userCredentials);

  return data
}

export async function confirmSignUp(email: string, code: string): Promise<void> {
  await axios.post(`/auth/register/confirm`, { email, code });
}

export async function signOut() {
  try {
    const token = localStorage.getItem('refresh_token')

    await API.post('/auth/logout', { refreshToken: token });
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export async function forgotPassword(email: string): Promise<void> {
  await axios.post(`/auth/password/reset`,
    { email },
    { headers: { 'AC-Client-Environment': 'admin-ui' } }
  );
}

export async function forgotPasswordSubmit(code: string, password: string): Promise<void> {
  await axios.post(`/auth/password/reset/confirm`, { code, password });
}

export async function checkAuth(refreshToken: string): Promise<LoginResponse> {
  const { data } = await axios.post(
    `/auth/refresh-access-token`,
    { refreshToken }
  );

  return data
}


export async function resendConfirmation(email: string): Promise<void> {
  await axios.post(`/auth/register/resend-confirmation`, { email });
}