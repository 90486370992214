import API from './api'

import { UsersProfile } from './auth';

export interface ChangePasswordDto {
  oldPassword: string;
  newPassword: string;
}
export interface ViewingUrlDto {
  viewingUrl: string;
}

export interface StaticValues {
  stream: {
    type: StaticValuesItem[],
    status: StaticValuesItem[],
    statusGroup: StaticValuesItem[]
  },
  user: {
    role: StaticValuesItem[]
  }
}

export interface StaticValuesItem {
  text?: string;
  value?: string;
  visible?: boolean;
}


export enum RoleEnum {
  Streamer = 'STREAMER',
  Admin = 'ADMIN',
  StreamProvider = 'STREAM_PROVIDER',
  Tester = 'TESTER',
  AppleDemoAccount = 'APPLE_DEMO_ACCOUNT',
}



export async function fetchUsersProfile(): Promise<UsersProfile> {
  const { data } = await API.get('/me');

  return data
}



export async function changePassword(changePasswordCredentials: ChangePasswordDto): Promise<void> {
  await API.post(
    '/me/password/change',
    { ...changePasswordCredentials, newPasswordForConfirmation: changePasswordCredentials.newPassword }
  );
}

export async function fetchViewingUrl(): Promise<ViewingUrlDto> {
  const { data } = await API.get('/viewing-url');

  return data
}


export async function fetchFiltersValues(): Promise<StaticValues> {
  const { data } = await API.get('/static-values');

  return data
}