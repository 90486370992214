import { UsersProfile } from '../api/auth';

export interface AppState {
  isUserLogged: boolean;
  isStateInitialized: boolean;
  user?: UsersProfile;
}

export const initialState: AppState = {
  isUserLogged: false,
  isStateInitialized: false,
};
