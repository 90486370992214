export enum KeysLocalStorage {
  access_token = 'access_token',
  refresh_token = 'refresh_token',
  expires_in = 'expires_in',
  expires_in_refresh = 'expires_in_refresh'
}

class LocalStorageHelpers {
  static clearAllLocalStorage() {
    for (const enumKey in KeysLocalStorage) {
      localStorage.removeItem(KeysLocalStorage[enumKey as keyof typeof KeysLocalStorage]);
    }
  }
}

export default LocalStorageHelpers;
