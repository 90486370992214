import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Button, Divider, Drawer, Typography, Dropdown, Tooltip } from 'antd';

import LocalStorageHelpers from '../../../helpers/LocalStorageHelpers';

import {
  LogoutOutlined,
  MenuOutlined,
  DownOutlined,
  UserOutlined,
  ClockCircleOutlined,
  LockOutlined
} from '@ant-design/icons';

import { useAppStateValue } from '../../../state/StateContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ActionClip from '../../../assets/LogoBlack.png';
import ActionClipWhite from '../../../assets/Logo.png';

import config from '../../../api/config';
import { signOut } from '../../../api/auth';

import './header.scss';
import { PATH_DASHBOARD } from '../../../routes/paths';

const { Header } = Layout;
const { Text } = Typography;


export default function HeaderComponent() {
  let location = useLocation();
  // let history = useHistory();

  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [visible, setVisible] = useState(false);
  const [{ user }, dispatch] = useAppStateValue();
  const { width } = useWindowDimensions();


  useEffect(() => {
    setCurrentPage(location.pathname)

  }, [location])

  const handleClick = (e: any) => {
    setVisible(false)
  };

  const logOut = async () => {
    await signOut();

    LocalStorageHelpers.clearAllLocalStorage()
    dispatch({ type: 'clearState' });
  };

  const drawerTitle = (
    <>
      <Link to={PATH_DASHBOARD.streams}>
        <img src={ActionClipWhite} alt="logo" style={{ width: 120 }} />
      </Link>
    </>
  )
  const drawerFooter = (
    <>
      <div className="change-password-block">
        <Link to={PATH_DASHBOARD.settings}>
          <LockOutlined style={{ color: '#fff' }} />
          <span className='text'>Change password</span>
        </Link>
      </div>

      <div className="logout-block">
        <Button type="text" icon={<LogoutOutlined style={{ color: '#fff' }} />} onClick={logOut} className='logout-btn-mobile'>Logout</Button>
      </div>
    </>
  )


  const navMenuItems = [
    {
      label: <Link to={PATH_DASHBOARD.streams}>
        <span className='nav-item'>Streams</span>
      </Link>,
      key: PATH_DASHBOARD.streams,
    },
    {
      label: <Link to={PATH_DASHBOARD.users}>
        <span className='nav-item'>Users</span>
      </Link>,
      key: PATH_DASHBOARD.users,
    },
    // {
    //   label: <Link to={PATH_DASHBOARD.links}>
    //     <span className='nav-item'>Links</span>
    //   </Link>,
    //   key: PATH_DASHBOARD.links,
    // },
  ];

  const userInfoMenuItems = [
    {
      label: <Link to={PATH_DASHBOARD.settings}>
        <Text>Change password</Text>
      </Link>,
      key: 'changePassword'
    },
    {
      label: <Divider style={{ margin: '3px 0' }} />,
      key: 'divider',
      disabled: true,
      style: { cursor: 'default' }
    },
    {
      label: <Text>Logout</Text>,
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: logOut
    },
  ];

  const userInfoMenu = (<Menu items={userInfoMenuItems} />)

  const textTooltipAvailableTime = `Available streaming time`

  return (

    <>
      {width as number < 1025 &&
        <Drawer
          title={drawerTitle}
          footer={drawerFooter}
          width={width as number < 400 ? 350 : 378}
          className='sider-mobile'
          placement='left'
          onClose={() => setVisible(false)}
          open={visible}
          key='left'
        >
          <div className="user-mail-sidebar">
            <UserOutlined className='icon' />
            <span className='text'>{user?.email}</span>
          </div>

          <Menu
            items={!user?.roles.includes(config.demoAccountName) ? navMenuItems : navMenuItems.filter(item => item.key !== '/payment')}
            onClick={handleClick}
            selectedKeys={[currentPage]}
            className='nav-menu-mobile'>
          </Menu>
        </Drawer>
      }

      <Header className='header'>

        {width as number < 1025 &&
          <Button className='trigger' type="text" onClick={() => setVisible(true)}>
            <MenuOutlined />
          </Button>
        }

        {width as number > 1024 &&
          <div className='header-menu'>
            <Link to={PATH_DASHBOARD.streams}>
              <img src={ActionClip} alt="logo" style={{ width: 157 }} />
            </Link>


            <Menu
              items={!user?.roles.includes(config.demoAccountName) ? navMenuItems.splice(0, 3) : [navMenuItems[0], navMenuItems[2]]}
              onClick={handleClick}
              selectedKeys={[currentPage]}
              mode="horizontal"
              className='header-menu-nav'>
            </Menu>
          </div>
        }

        <div>
          {user?.customer &&
            <Tooltip placement="bottom" title={textTooltipAvailableTime}>
              <ClockCircleOutlined />
              <Text style={{ cursor: 'default', marginLeft: 12 }}>{user?.customer.aggregatedBalance.streamingTime.hoursString} </Text>
            </Tooltip>
          }
          {width as number > 1024 &&
            <div className='info-block-desktop'>
              <Divider type='vertical' />

              <Dropdown overlay={userInfoMenu} className='user-info-dropdown'>
                <Text >{user?.email}<DownOutlined style={{ marginLeft: 10 }} /></Text>
              </Dropdown>
            </div>}
        </div>

      </Header>
    </>
  )
}
