import axios from 'axios';
import Qs from 'query-string';

// config
import config from './config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: config.urlApiServer,
  paramsSerializer: (params) => Qs.stringify(params, {
    arrayFormat: 'bracket',
    // encode: false,
  })
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data.error) || 'Something went wrong')
);

export default axiosInstance;
