import { Spin } from "antd";

export const Loader = () => {
    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Spin />
        </div>
    );
};