import axios from 'axios';
import Qs from 'query-string';
import jwt_decode from "jwt-decode";
import config from './config'
import { checkAuth } from './auth'
import LocalStorageHelpers from '../helpers/LocalStorageHelpers';
import { PATH_AUTH, PATH_ERROR } from '../routes/paths';

const api = axios.create({
  withCredentials: false,
  baseURL: config.urlApiServer,
  paramsSerializer: (params) => Qs.stringify(params, {
    arrayFormat: 'bracket',
  }),
});

api.interceptors.request.use(async (config) => {

  if (!!localStorage.getItem('access_token')) {
    const expiresIn = localStorage.getItem('expires_in') as string;
    const expiresInRefresh = localStorage.getItem('expires_in_refresh') as string;

    if (+expiresInRefresh * 1000 > Date.now()) {

      if (+expiresIn * 1000 < Date.now()) {
        const refresh_token = localStorage.getItem('refresh_token') as string;

        try {
          let newToken = await checkAuth(refresh_token);

          var decoded: any = jwt_decode(newToken.accessToken);
          var decodedRefresh: any = jwt_decode(newToken.refreshToken);

          localStorage.setItem('access_token', newToken.accessToken)
          localStorage.setItem('refresh_token', newToken.refreshToken)
          localStorage.setItem('expires_in', decoded.exp)
          localStorage.setItem('expires_in_refresh', decodedRefresh.exp)
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      LocalStorageHelpers.clearAllLocalStorage()
      window.location.reload()
    }
  }
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
  return config;
})


api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      LocalStorageHelpers.clearAllLocalStorage()
      window.location.href = PATH_AUTH.login
    }

    if (error.response.status === 500) window.location.href = PATH_ERROR[500]

    return Promise.reject(error.response.data.error);
  }
);

export default api