export const {
  REACT_APP_ENV: environment = 'dev',
  REACT_APP_API_URL: urlApiServer = '',
  REACT_APP_STRIPE_PUB_KEY: stripeKey = '',
} = process.env;

const config = {
  urlApiServer,
  environment,
  stripeKey,
  demoAccountName: 'APPLE_DEMO_ACCOUNT',
  createdAtFrom: '2023-03-31',
  eventId: 'a9e36b24-1624-473e-9ca9-0f85ffa4eeb6'
};

console.log('Environment', environment);

export default config;
